import { createAsyncThunk } from '@reduxjs/toolkit';

import { minesiderBackendService } from 'app/api/service/minesider-backend-service';

interface UpdateUserParams {
  id: string;
  user: MinesiderBackend.UpdateCrmUser;
}

interface DeleteUserParams {
  user: MinesiderBackend.CrmUser;
  crmCustomerId: string;
}

export const getUsersResponse = createAsyncThunk('users/getUsersResponse', async (crmCustomerId: string) =>
  minesiderBackendService.getUsers(crmCustomerId),
);

export const createUserResponse = createAsyncThunk(
  'users/createUserResponse',
  async (user: MinesiderBackend.CreateCrmUser) => minesiderBackendService.createUser(user),
);

export const updateUserResponse = createAsyncThunk<MinesiderBackend.CrmUser, UpdateUserParams>(
  'users/updateUserResponse',
  async (params) => minesiderBackendService.updateUser(params.id, params.user),
);

export const deleteUserResponse = createAsyncThunk<MinesiderBackend.CrmUser, DeleteUserParams>(
  'users/deleteUserResponse',
  async (params) => minesiderBackendService.deleteUser(params.user.id, params.crmCustomerId).then(() => params.user),
);
export const resendVerificationEmail = createAsyncThunk<void, string>('users/resendVerificationEmail', async (userId) =>
  minesiderBackendService.resendVerificationEmail(userId),
);
