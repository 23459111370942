import React, { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, Route, Routes, useLocation, useNavigationType } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { Header } from 'app/features/header';
import { navigationService } from 'app/service/navigation/navigation-service';
import { AuthCallback, CheckAuth, Logout, ProvAuthCallback } from 'app/features/auth';
import { ContextProvider } from 'app/features/context-provider';
import { useScrollToTop } from 'app/hooks';
import { GtmNoscript } from 'app/components/gtm';
import { GlobalFatalErrorBoundary } from 'app/components/error';
import { HelmetHead } from 'app/components/helmet-head';
import { config } from './config/config';
import { Footer } from './features/footer';
import { ToastMessage } from './features/toast-message/toast-message';

if (config.sentry.enabled === 'true') {
  const { REACT_APP_SENTRY_RELEASE } = process.env;
  Sentry.init({
    dsn: config.sentry.dsn,
    ignoreErrors: [
      // Session timeouts
      /Authorization response state ([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}) doesn't match request state ([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$)/,

      // Rehydration mismatch
      /Authorization response state ([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}) doesn't match request state undefined/,

      // Operational messages might fail, useless for us.
      /\/operationalmessages\/customer\/\d+$/,

      // Browser specific errors
      /chrome-extension:\/\//,
      /@webkit-masked-url\(:\/\/hidden\/\)/,
    ],
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: Number.parseInt(config.sentry.tracingRate || '1.0', 10) || 1.0,
    release: REACT_APP_SENTRY_RELEASE,
    environment: config.sentry.environment,
    replaysOnErrorSampleRate: 1,
    replaysSessionSampleRate: 0,
    normalizeDepth: 5,
  });
}
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const App: React.FC = () => {
  useScrollToTop();

  return (
    <SentryRoutes>
      <Route path={navigationService.PAGES.logout.url} element={<Logout />} />
      <Route
        path="/*"
        element={
          <GlobalFatalErrorBoundary>
            <GtmNoscript />
            <div data-testid="app">
              <ToastMessage />
              <Header />
              <HelmetHead />
              <SentryRoutes>
                <Route path={navigationService.PAGES.authCallback.url} element={<AuthCallback />} />
                <Route path={navigationService.PAGES.provAuthCallback.url} element={<ProvAuthCallback />} />
                <Route
                  path="/*"
                  element={
                    <CheckAuth>
                      <ContextProvider />
                    </CheckAuth>
                  }
                />
              </SentryRoutes>
              <Footer />
            </div>
          </GlobalFatalErrorBoundary>
        }
      />
    </SentryRoutes>
  );
};
