import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';

import { logout, startProvAuth } from 'app/store/actions/auth-actions';
import { getSubscriptions, updateSubscriptions } from '../actions/subscription-thunks';
import { FetchStatus, SubscriptionState } from '../root-types';

const initialState: SubscriptionState = {
  getSubscriptionsFetchStatus: FetchStatus.NOT_STARTED,
  updateSubscriptionsStatus: FetchStatus.NOT_STARTED,
  subscriptions: {
    subNewsletters: false,
    subMagazine: false,
    subNewsletterAltiboxMoviesSeries: false,
    operationalMessages: false,
  },
};

const subscriptionsSlice: Slice<SubscriptionState> = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getSubscriptions.fulfilled,
      (state, action: PayloadAction<MinesiderBackend.CrmUserSubscriptions>) => {
        const { subMagazine, subNewsletters, subNewsletterAltiboxMoviesSeries, operationalMessages } = action.payload;
        state.subscriptions = {
          subMagazine,
          subNewsletters,
          subNewsletterAltiboxMoviesSeries,
          operationalMessages,
        };
        state.getSubscriptionsFetchStatus = FetchStatus.FULFILLED;
      },
    );
    builder.addCase(getSubscriptions.pending, (state) => {
      state.getSubscriptionsFetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(getSubscriptions.rejected, (state) => {
      state.getSubscriptionsFetchStatus = FetchStatus.REJECTED;
    });
    builder.addCase(updateSubscriptions.pending, (state) => {
      state.updateSubscriptionsStatus = FetchStatus.PENDING;
    });
    builder.addCase(updateSubscriptions.rejected, (state) => {
      state.updateSubscriptionsStatus = FetchStatus.REJECTED;
    });
    builder.addCase(updateSubscriptions.fulfilled, (state, payload) => {
      const { subMagazine, subNewsletters, subNewsletterAltiboxMoviesSeries, operationalMessages } = payload.payload;
      state.subscriptions = {
        subMagazine,
        subNewsletters,
        subNewsletterAltiboxMoviesSeries,
        operationalMessages,
      };
      state.updateSubscriptionsStatus = FetchStatus.FULFILLED;
    });

    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(startProvAuth, () => initialState);
  },
});

const { reducer } = subscriptionsSlice;

export { reducer };
