import * as React from 'react';

import { config } from 'app/config/config';
import { clearAuthCookies } from 'app/utils/cookies';
import { logout } from 'app/store/actions/auth-thunks';
import { ParamUtils } from 'app/utils/param-utils';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { resolveAltiboxFrontPageDomain } from 'app/utils/navigation-utils';
import { FetchStatus } from 'app/store/root-types';
import { clearPersistedState } from 'app/store/store-utils';

const Logout = (): null => {
  const dispatch = useAppDispatch();
  const { fetchStatus } = useAppSelector((state) => state.auth);

  React.useEffect(() => {
    if (!config.auth.logoutUrl || !config.app.maintenanceUrl) {
      throw Error('environment variable LOGOUT_URL or MAINTENANCE_URL missing!');
    }

    // Start the logout process
    if (fetchStatus === FetchStatus.NOT_STARTED) {
      dispatch(logout());
      return;
    }

    if (fetchStatus === FetchStatus.FULFILLED || fetchStatus === FetchStatus.REJECTED) {
      clearAuthCookies();
      clearPersistedState();

      const isMaintenance = ParamUtils.getQueryParam('maintenance');
      const logoutRedirectUrl = isMaintenance
        ? config.app.maintenanceUrl
        : `https://${resolveAltiboxFrontPageDomain()}`;
      window.location.assign(logoutRedirectUrl);
    }
  });

  return null;
};

export { Logout };
