import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Breakpoint, Typography } from 'app/component-library-wave';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';

import { fetchNetworkSettings, updateNetworkSettings } from 'app/store/actions/network-thunks';
import { FetchStatus } from 'app/store/root-types';
import { ContainerFixed } from 'app/components/container';
import { Spinner } from 'app/components/spinner';
import { NetworkSettingsForm } from './network-settings-form';
import { ActiveCustomerLocationPage } from 'app/pages/page-wrapper';
import { TabbedContent } from 'app/features/tabbed-content/tabbed-content';
import { HiddenH1 } from 'app/components/hidden-h1';
import { internetSettingsTabList } from 'app/utils/navigation-utils';

import styles from './network-settings.module.scss';

export const NetworkSettings: ActiveCustomerLocationPage = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const customerId = useAppSelector((state) => state.userContext.selectedCustomerLocation?.customerCrmId);
  const servicePointId = useAppSelector((state) => state.userContext.selectedCustomerLocation?.servicePointId);
  const {
    fetchStatus,
    data: networkData,
    updateNetworkSettings: { fetchStatus: updateStatus },
  } = useAppSelector((state) => state.network);

  const tabList = internetSettingsTabList({ userContext: props.userContext, networkData });

  if (!servicePointId || !customerId) {
    return <Spinner />;
  }

  useEffect(() => {
    if (fetchStatus === FetchStatus.NOT_STARTED) {
      dispatch(fetchNetworkSettings({ servicePointId, customerId }));
    }
  }, [updateStatus, fetchStatus]);

  const handleSubmit = useCallback(
    (network: MinesiderBackend.UpdateNetwork) => {
      dispatch(updateNetworkSettings({ customerId, servicePointId, network }));
    },
    [customerId, servicePointId],
  );

  const noHomeGateway = networkData?.installationType === 'NO_HOMEGATEWAY';

  return (
    <ContainerFixed isNarrow={false}>
      <HiddenH1 focusHeading={false}>{t('pages.network.advanced.name')}</HiddenH1>
      <TabbedContent selectedTabKey={'network'} tabList={tabList}>
        <div className={styles.networkSettingsContainer}>
          <Typography component="h2" variant="headline4" className={styles.header} maxBreakpoint={Breakpoint.TABLET}>
            {t('pages.network.advanced.settings.name')}
          </Typography>
          {(fetchStatus === FetchStatus.NOT_STARTED || fetchStatus === FetchStatus.PENDING) && <Spinner />}
          {(fetchStatus === FetchStatus.REJECTED || (fetchStatus === FetchStatus.FULFILLED && !networkData)) && (
            <Alert
              alertType="warning"
              heading={t('pages.network.advanced.settings.fetchingFailed')}
              headingElement="strong"
              isExpandable={false}
              role="alert"
            />
          )}
          {fetchStatus === FetchStatus.FULFILLED && networkData && noHomeGateway && (
            <Alert
              alertType="warning"
              heading={t('pages.network.advanced.settings.noHomeGateway')}
              headingElement="strong"
              isExpandable={false}
              role="alert"
            />
          )}
          {fetchStatus === FetchStatus.FULFILLED && networkData && !noHomeGateway && (
            <NetworkSettingsForm initialValues={networkData} fetchStatus={updateStatus} onSubmit={handleSubmit} />
          )}
        </div>
      </TabbedContent>
    </ContainerFixed>
  );
};
