import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { v4 as uuid } from 'uuid';
import * as Sentry from '@sentry/react';

import { AuthService } from 'app/service/auth/auth-service';
import { config } from 'app/config/config';
import { startLogout } from 'app/utils/navigation-utils';
import { isCancel } from 'app/utils/error-utils';

export interface RestServiceConfig {
  abortSignal: AbortSignal;
}

class RestService {
  private static readonly BE_BASE_URL: string = `${config.app.contextPath}/api`;

  private readonly axiosInstance: AxiosInstance;

  private readonly abortSignal: AbortSignal;

  constructor(restServiceConfig: RestServiceConfig) {
    this.abortSignal = restServiceConfig.abortSignal;

    this.axiosInstance = axios.create({
      baseURL: RestService.BE_BASE_URL,
      signal: this.abortSignal,
    });

    this.axiosInstance.interceptors.request.use((request) => {
      // config request here
      const token: string | undefined = AuthService.getAccessToken();
      const sessionTicket: string | undefined = AuthService.getSessionTicket();
      const isAnonymous = request.headers?.XAnonymousRequest === 'true';

      if (request.headers) {
        if (token) {
          request.headers.Authorization = `Bearer ${token}`;
        }

        if (sessionTicket) {
          request.headers.SessionTicket = sessionTicket;
        }

        request.headers['X-Correlation-Id'] = uuid();

        if (isAnonymous) {
          delete request.headers.Authorization;
          delete request.headers.XAnonymousRequest;
          delete request.headers.SessionTicket;
        }
      }

      return request;
    });

    this.axiosInstance.interceptors.response.use(
      (response) => cleanObject(response.data),
      (error) => {
        if (isCancel(error)) {
          return Promise.reject(error);
        }
        Sentry.captureException(error);

        // handle exceptional BE errors here
        if (error.response?.status === 401) {
          startLogout();
        }
        return Promise.reject(error.response);
      },
    );
  }

  public get<T>(url: string, requestConfig?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.get(url, requestConfig);
  }

  public post<T>(url: string, data?: any, requestConfig?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.post(url, data, requestConfig);
  }

  public delete<T>(url: string, requestConfig?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.delete(url, requestConfig);
  }

  public put<T>(url: string, data?: any, requestConfig?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.put(url, data, requestConfig);
  }

  public patch<T>(url: string, data?: Record<string, unknown>, requestConfig?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.patch(url, data, requestConfig);
  }
}

const cleanObject = (obj: any): any => {
  for (const propName in obj) {
    if (obj.hasOwnProperty(propName) && (obj[propName] === null || obj[propName] === undefined)) {
      delete obj[propName];
    }
  }
  return obj;
};

const restService: RestService = new RestService({ abortSignal: new AbortController().signal });

export { restService, RestService };
