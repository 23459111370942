import { createSlice, isAnyOf, Slice } from '@reduxjs/toolkit';
import { FetchStatus, NetworkSettingsState } from 'app/store/root-types';
import { clearNetworkSettingsForm } from 'app/store/actions/network-settings-actions';
import {
  setSingleNetworkSetting,
  fetchNetworkSettings,
  updateNetworkSettings,
  setWifiSettings,
  updateWifiAccessPoint,
  fetchFixedWirelessAccessStatus,
  setManagedWifiSetting,
} from 'app/store/actions/network-thunks';
import {
  clearFixedWirelessAccess,
  clearAccessPointUpdateStatus,
  wifiClearUpdate,
} from 'app/store/actions/network-actions';
import { selectCustomerLocation } from 'app/store/actions/user-context-actions';
import { logout } from '../actions/auth-actions';
import { networkUpdated } from '../actions/network-actions';

const initialState: NetworkSettingsState = {
  fetchStatus: FetchStatus.NOT_STARTED,
  updateNetworkSettings: {
    fetchStatus: FetchStatus.NOT_STARTED,
  },
  updateWifiSettings: {
    fetchStatus: FetchStatus.NOT_STARTED,
  },
  updateAccessPoints: {
    fetchStatus: FetchStatus.NOT_STARTED,
  },
  fixedWirelessAccess: {
    fetchStatus: FetchStatus.NOT_STARTED,
  },
};

const networkSlice: Slice<NetworkSettingsState> = createSlice({
  name: 'network',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(selectCustomerLocation, (state, action) => {
      if (state.data?.servicePointId !== action.payload.customerLocation.servicePointId) {
        return initialState;
      }
      return state;
    });
    builder.addCase(fetchNetworkSettings.pending, (state) => {
      state.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(fetchNetworkSettings.fulfilled, (state, action) => {
      state.data = action.payload;
      state.fetchStatus = FetchStatus.FULFILLED;
    });
    builder.addCase(fetchNetworkSettings.rejected, (state) => {
      state.fetchStatus = FetchStatus.REJECTED;
    });
    builder.addCase(wifiClearUpdate, (state) => {
      state.updateWifiSettings = {
        error: undefined,
        fetchStatus: FetchStatus.NOT_STARTED,
      };
    });
    builder.addCase(networkUpdated, (state, action) => {
      state.data = action.payload;
      state.fetchStatus = FetchStatus.FULFILLED;
    });
    builder.addCase(updateNetworkSettings.pending, (state) => {
      state.updateNetworkSettings.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(updateNetworkSettings.fulfilled, (state, action) => {
      state.data = action.payload;
      state.updateNetworkSettings.fetchStatus = FetchStatus.FULFILLED;
    });
    builder.addCase(updateNetworkSettings.rejected, (state) => {
      state.updateNetworkSettings.fetchStatus = FetchStatus.REJECTED;
    });
    builder.addCase(clearNetworkSettingsForm, (state) => {
      state.updateNetworkSettings = initialState.updateNetworkSettings;
    });
    builder.addCase(updateWifiAccessPoint.pending, (state, action) => {
      state.updateAccessPoints = {
        ...initialState.updateAccessPoints,
        itemBeingUpdated: action.meta.arg.id,
        fetchStatus: FetchStatus.PENDING,
      };
    });
    builder.addCase(updateWifiAccessPoint.fulfilled, (state, action) => {
      state.data = action.payload;
      state.updateAccessPoints = {
        ...state.updateAccessPoints,
        fetchStatus: FetchStatus.FULFILLED,
      };
    });
    builder.addCase(updateWifiAccessPoint.rejected, (state) => {
      state.updateAccessPoints = {
        ...initialState.updateAccessPoints,
        fetchStatus: FetchStatus.REJECTED,
      };
    });
    builder.addCase(clearAccessPointUpdateStatus, (state) => {
      state.updateAccessPoints = initialState.updateAccessPoints;
    });

    builder.addCase(fetchFixedWirelessAccessStatus.pending, (state) => {
      state.fixedWirelessAccess.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(fetchFixedWirelessAccessStatus.fulfilled, (state, action) => {
      state.fixedWirelessAccess.data = action.payload;
      state.fixedWirelessAccess.fetchStatus = FetchStatus.FULFILLED;
    });
    builder.addCase(fetchFixedWirelessAccessStatus.rejected, (state) => {
      state.fixedWirelessAccess.fetchStatus = FetchStatus.REJECTED;
    });
    builder.addCase(clearFixedWirelessAccess, (state) => {
      state.fixedWirelessAccess = initialState.fixedWirelessAccess;
    });
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addMatcher(
      isAnyOf(setWifiSettings.fulfilled, setSingleNetworkSetting.fulfilled, setManagedWifiSetting.fulfilled),
      (state, action) => {
        state.data = action.payload;
        state.updateWifiSettings = {
          ...state.updateWifiSettings,
          fetchStatus: FetchStatus.FULFILLED,
        };
      },
    );
    builder.addMatcher(
      isAnyOf(setWifiSettings.rejected, setSingleNetworkSetting.rejected, setManagedWifiSetting.rejected),
      (state, action) => {
        state.updateWifiSettings = {
          ...state.updateWifiSettings,
          error: {
            code: action.payload?.errorCode,
            message: action.payload?.errorMessage || '',
          },
          fetchStatus: FetchStatus.REJECTED,
        };
      },
    );
    builder.addMatcher(
      isAnyOf(setWifiSettings.pending, setSingleNetworkSetting.pending, setManagedWifiSetting.pending),
      (state) => {
        state.updateWifiSettings = {
          ...state.updateWifiSettings,
          fetchStatus: FetchStatus.PENDING,
        };
      },
    );
  },
});

const { reducer } = networkSlice;

export { reducer };
