import { createAsyncThunk } from '@reduxjs/toolkit';
import { minesiderBackendService } from 'app/api/service/minesider-backend-service';
import * as SentryUtils from 'app/utils/sentry-utils';
import { ErrorTypes, FriendlyErrorMessage } from 'app/store/root-types';
import { dismissVerificationPrompt, raiseGlobalError } from 'app/store/actions/core-actions';
import {
  fetchUserContextFulfilled,
  fetchUserContextStarted,
  selectCustomerLocation,
} from 'app/store/actions/user-context-actions';
import { CrmSystem, CustomerLocationContext } from 'app/store/types/user-context-types';
import { AppThunk } from 'app/store/configure-store';
import { startLogout } from 'app/utils/navigation-utils';

function findAccountsInUserContext(userContext: MinesiderBackend.UserContext): MinesiderBackend.Account[] {
  if (!userContext.accounts) {
    return [];
  }
  return userContext.accounts;
}

function findLocationsInAccounts(accounts: MinesiderBackend.Account[]): MinesiderBackend.ServiceLocation[] {
  return accounts.reduce((accumulator: MinesiderBackend.ServiceLocation[], account) => {
    const accountLocations = account.locations || [];

    return accumulator.concat(accountLocations);
  }, []);
}

// Thunks
export const verifyContactDetails =
  (userName: string): AppThunk =>
  async (dispatch) => {
    minesiderBackendService.confirmUserDetails(userName);
    dispatch(dismissVerificationPrompt());
  };

export const getUserNeedToConfirm = createAsyncThunk<MinesiderBackend.ContactDetailsConfirmation, string>(
  'userContext/fetchUserConfirmStatus',
  async (userName) => minesiderBackendService.getUserNeedToConfirm(userName),
);

export const setSelectedCustomerLocation =
  ({ customerLocation }: { customerLocation: CustomerLocationContext }): AppThunk =>
  async (dispatch) => {
    dispatch(selectCustomerLocation({ customerLocation }));
  };

export const fetchUserContext = (): AppThunk => async (dispatch, getState) => {
  dispatch(fetchUserContextStarted());

  const isCustomerServiceRepresentative = getState().auth.isCsr;
  try {
    const userContext = isCustomerServiceRepresentative
      ? await minesiderBackendService.getUserContextForCsr()
      : await minesiderBackendService.getUserContext();

    if (userContext.user?.username) {
      SentryUtils.setUser(userContext.user);
    }

    const accounts = findAccountsInUserContext(userContext);

    const locations = findLocationsInAccounts(accounts);

    // No accounts associated with login
    if (accounts.length === 0) {
      dispatch(
        raiseGlobalError({
          message: FriendlyErrorMessage.NO_ACCOUNTS_FOUND_FOR_CUSTOMER,
          type: ErrorTypes.NON_RETRYABLE_SYSTEM_ERROR,
        }),
      );
      return;
    }

    // Unknown state
    if (accounts.every((acc) => acc.customer?.status === 'UNKNOWN')) {
      dispatch(
        raiseGlobalError({
          message: FriendlyErrorMessage.UNKNOWN_CUSTOMER_STATUS,
          type: ErrorTypes.NON_RETRYABLE_SYSTEM_ERROR,
        }),
      );
      return;
    }

    // No active locations for customer
    if (locations.length === 0) {
      // Check if customer has been an inactive customer
      if (accounts.some((acc) => acc.customer?.status === 'INACTIVE')) {
        dispatch(fetchUserContextFulfilled(userContext));
        return;
      }
      // Confirmed no active locations for customer
      dispatch(
        raiseGlobalError({
          message: FriendlyErrorMessage.NO_LOCATIONS_FOUND_FOR_CUSTOMER,
          type: ErrorTypes.NON_RETRYABLE_SYSTEM_ERROR,
        }),
      );
      return;
    }

    // Is customer associated with any Altibox partner?
    if (!accounts.some((account) => account.partner && account.partner.id)) {
      throw new Error('[user-context-actions.ts] No partnerId found');
    }

    if (accounts.every((account) => account.customer?.crmSystem === CrmSystem.NONE)) {
      startLogout({ isMaintenance: true });
      return;
    }

    // All is good
    dispatch(fetchUserContextFulfilled(userContext));
  } catch (e: any) {
    if (e.data && e.data.errorCode === 'NO_ACCOUNT') {
      dispatch(
        raiseGlobalError({
          message: FriendlyErrorMessage.NO_ACCOUNTS_FOUND_FOR_CUSTOMER,
          type: ErrorTypes.NON_RETRYABLE_SYSTEM_ERROR,
          error: e,
        }),
      );
    } else {
      dispatch(raiseGlobalError(e));
    }
  }
};
