import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';

import { logout, startProvAuth } from 'app/store/actions/auth-actions';
import { FetchStatus, ConsentState } from 'app/store/root-types';
import { fetchConsent, saveConsent } from 'app/store/actions/consent-actions';
import { selectCustomerLocation } from 'app/store/actions/user-context-actions';

const initialState: ConsentState = {
  fetchStatus: FetchStatus.NOT_STARTED,
};

const consentSlice: Slice<ConsentState> = createSlice({
  name: 'consent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(selectCustomerLocation, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(startProvAuth, () => initialState);
    builder.addCase(fetchConsent.pending, (state) => {
      state.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(fetchConsent.fulfilled, (state, action: PayloadAction<MinesiderBackend.ConsentRestFacade>) => {
      state.consent = action.payload.consent;
      state.fetchStatus = FetchStatus.FULFILLED;
    });
    builder.addCase(fetchConsent.rejected, (state) => {
      state.fetchStatus = FetchStatus.REJECTED;
    });
    builder.addCase(saveConsent.pending, (state) => {
      state.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(saveConsent.fulfilled, (state, action: PayloadAction<MinesiderBackend.ConsentRestFacade>) => {
      state.consent = action.payload.consent;
      state.fetchStatus = FetchStatus.FULFILLED;
    });
    builder.addCase(saveConsent.rejected, (state) => {
      state.fetchStatus = FetchStatus.REJECTED;
    });
  },
});

const { reducer } = consentSlice;

export { reducer };
