import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  Breakpoint,
  Button,
  ButtonType,
  Input,
  Typography as T,
  UiComponentState,
} from 'app/component-library-wave';

import { useAppDispatch, useAppSelector } from 'app/hooks/redux-thunk';
import { navigationService } from 'app/service/navigation/navigation-service';
import { createNewEmailAccount } from 'app/store/actions/email-thunks';
import { FetchStatus } from 'app/store/root-types';
import {
  emailAddressIsValid,
  emailAccountPasswordComplexityIsOk,
  emailAccountPasswordIsValid,
  emailAccountPasswordLengthIsOk,
} from 'app/utils/validators';
import { IconMessage } from 'app/components/icon-message/icon-message';
import { emailSenderNameIsValid } from 'app/components/email/email-common/email-common';
import { useAppNavigation } from 'app/utils/navigation-utils';

import { CustomerPage } from 'app/pages/page-wrapper';
import { ContainerFixed } from 'app/components/container';
import { Spinner } from 'app/components/spinner/spinner';
import { AlertWarning, InfoCircle } from 'app/media';

import styles from './create-new-email.module.scss';
import commonEmailCss from '../email-common.module.scss';

export const CreateNewEmail: CustomerPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { goToPath } = useAppNavigation();
  const pageStatus = useAppSelector((state) => state.email.createNew.fetchStatus);
  const { domain } = useAppSelector((state) => state.email);
  const [emailHelpText] = useState<string>(t('pages.email.createNewAccount.emailValidationInformation'));
  const [passwordIsHidden, setPasswordIsHidden] = useState(true);
  const inputInit = {
    isTouched: false,
    isValid: false,
    value: '',
  };
  const [form, setForm] = useState({
    firstName: { ...inputInit },
    lastName: { ...inputInit },
    email: { ...inputInit },
    password: { ...inputInit },
  });

  useEffect(() => {
    if (pageStatus === FetchStatus.FULFILLED || !domain) {
      goToPath(navigationService.PAGES.email.url);
    }
  }, [pageStatus]);

  const formIsValid = () =>
    form.firstName.isValid && form.lastName.isValid && form.email.isValid && form.password.isValid;

  const submitCreateNewEmail = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    setForm({
      ...form,
      firstName: {
        ...form.firstName,
        isTouched: true,
      },
      lastName: {
        ...form.lastName,
        isTouched: true,
      },
      email: {
        ...form.email,
        isTouched: true,
      },
      password: {
        ...form.password,
        isTouched: true,
      },
    });

    if (formIsValid()) {
      dispatch(
        createNewEmailAccount({
          firstname: form.firstName.value.trim(),
          lastname: form.lastName.value.trim(),
          email: `${form.email.value.trim()}@${domain}`.toLowerCase(),
          password: form.password.value,
        }),
      );
    }
  };

  const emailAddressChanged = (e: ChangeEvent<HTMLInputElement> | FormEvent<HTMLInputElement>) => {
    const isValid = emailAddressIsValid(e.currentTarget.value, true);
    if (e.currentTarget.value === '' || isValid) {
      setForm({
        ...form,
        email: {
          isTouched: true,
          isValid,
          value: e.currentTarget.value,
        },
      });
    }
  };

  const firstNameUiComponentState = () =>
    form.firstName.isTouched && !form.firstName.isValid ? UiComponentState.ERROR : undefined;
  const lastNameUiComponentState = () =>
    form.lastName.isTouched && !form.lastName.isValid ? UiComponentState.ERROR : undefined;
  const emailUiComponentState = () =>
    form.email.isTouched && !form.email.isValid ? UiComponentState.ERROR : undefined;
  const passwordUiComponentState = () =>
    form.password.isTouched && !form.password.isValid ? UiComponentState.ERROR : undefined;
  const firstNameHelpText = () =>
    form.firstName.isTouched && !form.firstName.isValid
      ? t('pages.email.createNewAccount.validation.firstName')
      : undefined;
  const lastNameHelpText = () =>
    form.lastName.isTouched && !form.lastName.isValid
      ? t('pages.email.createNewAccount.validation.lastName')
      : undefined;

  return (
    <ContainerFixed isNarrow={true}>
      <form className={commonEmailCss.mobileEdge}>
        <T component="h1" variant="headline4" maxBreakpoint={Breakpoint.TABLET} className={commonEmailCss.heading}>
          {t('pages.email.createNewAccount.heading')}
        </T>
        <div className={commonEmailCss.formBorder}>
          <div className={commonEmailCss.formRow}>
            <Input
              label={t('pages.email.createNewAccount.firstName')}
              id="firstName"
              autoFocus={true}
              value={form.firstName.value}
              onChange={(e) => {
                setForm({
                  ...form,
                  firstName: {
                    isValid: emailSenderNameIsValid(e.currentTarget.value),
                    isTouched: true,
                    value: e.currentTarget.value,
                  },
                });
              }}
              uiComponentState={firstNameUiComponentState()}
              helpText={firstNameHelpText()}
            />
          </div>
          <div className={commonEmailCss.formRowTall}>
            <Input
              label={t('pages.email.createNewAccount.lastName')}
              id="lastName"
              onChange={(e) =>
                setForm({
                  ...form,
                  lastName: {
                    value: e.currentTarget.value,
                    isValid: emailSenderNameIsValid(e.currentTarget.value),
                    isTouched: true,
                  },
                })
              }
              uiComponentState={lastNameUiComponentState()}
              helpText={lastNameHelpText()}
            />
          </div>
          <div className={classNames(commonEmailCss.formRow)}>
            <div className={commonEmailCss.formRowEmail}>
              <Input
                label={t('pages.email.emailAddress')}
                id="email"
                onChange={(e) => emailAddressChanged(e)}
                aria-describedby="emailHelpText"
                value={form.email.value}
                uiComponentState={emailUiComponentState()}
              />
              <span className={styles.domain}>@{domain}</span>
            </div>

            <div className={styles.helpTextContainer}>
              <div className={styles.iconContainer}>
                {form.email.isValid || !form.email.isTouched ? (
                  <InfoCircle variant="filled" className={styles.infoIcon} />
                ) : (
                  <div>
                    <AlertWarning />
                  </div>
                )}
              </div>
              <T
                variant="uiText3"
                component="span"
                className={styles.helpText}
                maxBreakpoint={Breakpoint.TABLET}
                id="emailHelpText"
              >
                {emailHelpText}
              </T>
            </div>
          </div>
          <div className={commonEmailCss.formRow}>
            <Input
              label={t('pages.email.createNewAccount.password')}
              isPassword={true}
              isHidden={passwordIsHidden}
              id="password"
              onToggleHidden={() => setPasswordIsHidden(!passwordIsHidden)}
              helpText={
                passwordUiComponentState() === UiComponentState.ERROR
                  ? ''
                  : t('pages.email.emailPasswordRequirements.initial')
              }
              onChange={(e) =>
                setForm({
                  ...form,
                  password: {
                    value: e.currentTarget.value,
                    isTouched: true,
                    isValid: emailAccountPasswordIsValid(e.currentTarget.value),
                  },
                })
              }
              uiComponentState={passwordUiComponentState()}
            />
            {passwordUiComponentState() === UiComponentState.ERROR && (
              <>
                {!emailAccountPasswordLengthIsOk(form.password.value) && (
                  <IconMessage status="warning" text={t('pages.email.emailPasswordRequirements.length')} />
                )}
                {!emailAccountPasswordComplexityIsOk(form.password.value) && (
                  <IconMessage status="warning" text={t('pages.email.emailPasswordRequirements.complexity')} />
                )}
              </>
            )}
          </div>
        </div>
        {pageStatus !== FetchStatus.PENDING && (
          <div className={commonEmailCss.buttonRow}>
            <Button
              buttonType={ButtonType.SECONDARY}
              className={commonEmailCss.cancel}
              onClick={(e) => {
                e.preventDefault();
                goToPath(navigationService.PAGES.email.url);
              }}
              type="button"
            >
              {t('pages.email.cancel')}
            </Button>
            <Button buttonType={ButtonType.PRIMARY_B} onClick={submitCreateNewEmail}>
              {t('pages.email.createNewAccount.createEmailAccount')}
            </Button>
          </div>
        )}

        {pageStatus === FetchStatus.PENDING && <Spinner />}
      </form>
    </ContainerFixed>
  );
};
