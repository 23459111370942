import { createAsyncThunk } from '@reduxjs/toolkit';

import { minesiderBackendService } from 'app/api/service/minesider-backend-service';

export const fetchContactDetails = createAsyncThunk('contactDetails/fetchContactDetails', async (customerId: string) =>
  minesiderBackendService.getCustomerDetails(customerId),
);

interface ContactDetailsParams {
  customerId: string;
  contactDetails: MinesiderBackend.CustomerDetails;
}

export const saveContactDetails = createAsyncThunk<
  void,
  ContactDetailsParams,
  { rejectValue: MinesiderBackend.ErrorResponse }
>('contactDetails/saveContactDetails', async ({ customerId, contactDetails }) =>
  minesiderBackendService.updateCustomerDetails(customerId, contactDetails),
);
