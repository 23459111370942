import { FilterType, Page, Service } from './page-types';
import { config } from 'app/config/config';
import { Partner } from 'app/utils/partner-utils';
import { CrmSystem, CustomerType } from 'app/store/types/user-context-types';

const CONTEXT_PATH: string = config.app.contextPath;
export interface Pages {
  enterpriseDDOS: Page;
  enterpriseExtendedSecurity: Page;
  enterpriseFirewall: Page;
  enterpriseGuestWifi: Page;
  enterpriseInvoice: Page;
  enterpriseLineOverview: Page;
  enterpriseLineStatus: Page;
  enterpriseMyProducts: Page;
  enterpriseVOIP: Page;
  enterpriseInternetSettingsOverview: Page;
  enterpriseInternetSettingsNetworkSettings: Page;
  enterpriseWifiSettings: Page;
  enterpriseUpsale: Page;
  myHgw: Page;
  myUsageDK: Page;
  meshNetworkStoreDK: Page;
  invoiceInformation: Page;
  invoiceOpenNet: Page;
  invoiceArchiveSiebel: Page;
  voipDK: Page;
  orderDK: Page;
  account: Page;
  authCallback: Page;
  changePin: Page;
  deliveryStatus: Page;
  email: Page;
  emailDkEnterprise: Page;
  emailCreateNew: Page;
  emailChangeSenderName: Page;
  emailChangePassword: Page;
  emailDeleteAccount: Page;
  emailActivateAccount: Page;
  hgwUserManual: Page;
  home: Page;
  internet: Page;
  internetSettingsOverview: Page;
  internetSettingsOverviewDk: Page;
  internetSettingsNetworkSettings: Page;
  internetSettingsNetworkSettingsDk: Page;
  internetSettingsPortForwarding: Page;
  internetSettingsPortForwardingDk: Page;
  internetSettingsWifiBand: Page;
  internetSettingsWifiAdvanced: Page;
  invoiceLegacy: Page;
  invoiceLyse: Page;
  logout: Page;
  myProducts: Page;
  myProductsDkEnterprise: Page;
  orderTrackerOpenNet: Page;
  provAuthCallback: Page;
  streamingServices: Page;
  streamingService: Page;
  telephone: Page;
  tv: Page;
  tvStoreOpenNet: Page;
  users: Page;
  userEdit: Page;
  userCreate: Page;
  userDelete: Page;
  contactDetails: Page;
  contactDetailsEdit: Page;
  consent: Page;
  consentLyse: Page;
  internetSettingsWifiBridgeMode: Page;
  firewallCreateRule: Page;
  firewallUpdateRule: Page;
  foc: Page;
  inactiveMovies: Page;
  invoice: Page;
  ispDevices: Page;
  modellAStore: Page;
  movieRentalHistory: Page;
  myProfileDk: Page;
  myDevices: Page;
  newsletterSubscriptions: Page;
  relocation: Page;
  relocationFormVikenFiber: Page;
  relocationFormLyse: Page;
  meshNetworkStore: Page;
  tvStore: Page;
  voip: Page;
  xboxStore: Page;
  voipUsage: Page;
}

const commonNorwegianPartnerOnlyVisiblity = {
  partners: [Partner.DK_AltiboxOpenNet, Partner.NO_EidsivaBredbaand],
  filterType: FilterType.Blacklist,
};

export const commonDanishPartnerOnlyVisibility = {
  filterType: FilterType.Whitelist,
  partners: [Partner.DK_AltiboxOpenNet],
};

const emailCommonRequirements = {
  partnerVisibility: {
    filterType: FilterType.Blacklist,
    partners: [Partner.NO_EidsivaBredbaand],
  },
};

export const pages: Pages = {
  enterpriseLineOverview: {
    url: `${window.origin}/min-bedrift/linjeoversikt`,
    i18n: 'pages.enterprise.linjeoversikt.name',
    requireOneOfServices: [
      Service.Internet,
      Service.OffnetInternet,
      Service.IpVPN,
      Service.OffnetIpVPN,
      Service.MobileAccess,
      Service.EthernetP2P,
      Service.OffnetEthernetP2P,
    ],
    customerType: CustomerType.BEDRIFT,
    partnerVisibility: {
      partners: [Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Blacklist,
    },
  },
  enterpriseLineStatus: {
    url: `${window.origin}/min-bedrift/linjestatus`,
    i18n: 'pages.enterprise.linjestatus.name',
    requireOneOfServices: [
      Service.Internet,
      Service.OffnetInternet,
      Service.IpVPN,
      Service.OffnetIpVPN,
      Service.MobileAccess,
      Service.EthernetP2P,
      Service.OffnetEthernetP2P,
    ],
    partnerVisibility: {
      partners: [Partner.NO_Lyse, Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Blacklist,
    },
    customerType: CustomerType.BEDRIFT,
  },
  enterpriseInternetSettingsOverview: {
    url: `${CONTEXT_PATH}/internett/innstillinger/oversikt`,
    i18n: 'pages.enterprise.internetSettings.name',
    requireOneOfServices: [Service.Internet, Service.FixedWirelessAccess, Service.ManagedWifi],
    customerType: CustomerType.BEDRIFT,
    partnerVisibility: {
      partners: [Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Blacklist,
    },
  },
  enterpriseInternetSettingsNetworkSettings: {
    url: `${window.origin}/mine-sider/internett/min-hjemmesentral/`,
    i18n: 'pages.internetSettings.name',
    requireOneOfServices: [Service.Internet],
    partnerVisibility: {
      partners: [Partner.NO_Lyse, Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Blacklist,
    },
    customerType: CustomerType.BEDRIFT,
  },
  enterpriseWifiSettings: {
    url: `${CONTEXT_PATH}/internett/innstillinger/wifi`,
    i18n: 'pages.enterprise.wifiSettings.name',
    requireOneOfServices: [Service.ManagedWifi],
    customerType: CustomerType.BEDRIFT,
    partnerVisibility: {
      partners: [Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Blacklist,
    },
  },
  enterpriseGuestWifi: {
    url: `${CONTEXT_PATH}/internett/innstillinger/wifi/gjestenett`,
    i18n: 'pages.enterprise.guestWifi.name',
    requireOneOfServices: [Service.ManagedWifi],
    customerType: CustomerType.BEDRIFT,
    partnerVisibility: {
      partners: [Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Blacklist,
    },
  },
  enterpriseFirewall: {
    url: `${window.origin}/min-bedrift/brannmurinnstillinger`,
    i18n: 'pages.enterprise.firewall.name',
    requireOneOfServices: [Service.ManagedWifi],
    customerType: CustomerType.BEDRIFT,
    partnerVisibility: {
      partners: [Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Blacklist,
    },
  },
  enterpriseDDOS: {
    url: `${window.origin}/min-bedrift/ddos-beskyttelse`,
    i18n: 'pages.enterprise.ddos.name',
    requireOneOfServices: [Service.Internet],
    customerType: CustomerType.BEDRIFT,
    partnerVisibility: {
      partners: [Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Blacklist,
    },
  },
  enterpriseExtendedSecurity: {
    url: `${window.origin}/min-bedrift/utvidet-sikkerhet`,
    i18n: 'pages.enterprise.extendedSecurity.name',
    requireOneOfServices: [Service.ManagedWifi],
    customerType: CustomerType.BEDRIFT,
    partnerVisibility: {
      partners: [Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Blacklist,
    },
  },
  enterpriseUpsale: {
    url: `https://www.altibox.no/bedrift/bredband/bredbandspakke/`,
    openInNewTab: true,
    i18n: 'pages.enterprise.upsale.name',
    requireNotHavingAnyOfServices: [Service.ManagedWifi],
    customerType: CustomerType.BEDRIFT,
    partnerVisibility: {
      partners: [Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Blacklist,
    },
  },
  enterpriseInvoice: {
    url: `${window.origin}/min-bedrift/faktura`,
    i18n: 'pages.enterprise.invoice.name',
    partnerVisibility: {
      partners: [
        Partner.NO_Lyse,
        Partner.NO_HaugalandKraft,
        Partner.NO_KleppEnergi,
        Partner.NO_FinnaasKraftlag,
        Partner.NO_Stroeyma,
        Partner.DK_AltiboxOpenNet,
      ],
      filterType: FilterType.Blacklist,
    },
    customerType: CustomerType.BEDRIFT,
  },
  enterpriseMyProducts: {
    url: `${window.origin}/min-bedrift/mine-produkter`,
    i18n: 'pages.enterprise.myProducts.name',
    customerType: CustomerType.BEDRIFT,
    partnerVisibility: {
      partners: [Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Blacklist,
    },
  },
  enterpriseVOIP: {
    url: `${window.origin}/min-bedrift/ip-telefoni`,
    i18n: 'pages.enterprise.voip.name',
    customerType: CustomerType.BEDRIFT,
    partnerVisibility: {
      partners: [Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Blacklist,
    },
  },
  contactDetails: {
    url: `${CONTEXT_PATH}/konto/mitt-kundeforhold`,
    i18n: 'pages.contactDetails.name',
    partnerVisibility: { ...commonNorwegianPartnerOnlyVisiblity },
    crm: CrmSystem.INFONOVA,
  },
  contactDetailsEdit: {
    url: `${CONTEXT_PATH}/konto/mitt-kundeforhold/endre-kontaktinformasjon`,
    i18n: 'pages.contactDetailsEdit.name',
    partnerVisibility: { ...commonNorwegianPartnerOnlyVisiblity },
    crm: CrmSystem.INFONOVA,
  },
  consent: {
    url: `${CONTEXT_PATH}/konto/samtykke`,
    i18n: 'pages.consent.name',
    customerType: CustomerType.PRIVAT,
    partnerVisibility: {
      ...commonNorwegianPartnerOnlyVisiblity,
      partners: [...commonNorwegianPartnerOnlyVisiblity.partners, Partner.NO_Lyse],
    },
    crm: CrmSystem.INFONOVA,
  },
  consentLyse: {
    url: config.partnerConfig.lyse.consentUrl || 'https://www.lyse.no/minesider/mitt-kundeforhold/samtykke',
    i18n: 'pages.consent.name',
    customerType: CustomerType.PRIVAT,
    partnerVisibility: {
      partners: [Partner.NO_Lyse],
      filterType: FilterType.Whitelist,
    },
  },
  foc: {
    url: `${window.origin}/mine-sider/atv/velg-ditt-innhold`,
    i18n: 'pages.foc.name',
    requireOneOfServices: [Service.Tv],
    partnerVisibility: {
      partners: [Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Blacklist,
    },
    customerType: CustomerType.PRIVAT,
    crm: CrmSystem.INFONOVA,
  },
  inactiveMovies: {
    url: 'https://tv.altibox.no/film-og-serier/mitt-innhold',
    i18n: 'pages.inactive.movies.name',
    partnerVisibility: { ...commonNorwegianPartnerOnlyVisiblity },
    crm: CrmSystem.INFONOVA,
  },
  invoice: {
    url: `${window.origin}/mine-sider/faktura`,
    i18n: 'pages.invoice.name',
    partnerVisibility: {
      ...commonNorwegianPartnerOnlyVisiblity,
      partners: [
        ...commonNorwegianPartnerOnlyVisiblity.partners,
        Partner.NO_Lyse,
        Partner.NO_HaugalandKraft,
        Partner.NO_KleppEnergi,
        Partner.NO_FinnaasKraftlag,
        Partner.NO_Stroeyma,
        Partner.DK_AltiboxOpenNet,
      ],
    },
    customerType: CustomerType.PRIVAT,
    crm: CrmSystem.INFONOVA,
  },
  ispDevices: {
    url: `${CONTEXT_PATH}/internett/nettverk/nettverksenheter/:deviceId`,
    i18n: 'pages.network.ispDevices.name',
    requireOneOfServices: [Service.Internet],
    partnerVisibility: { ...commonNorwegianPartnerOnlyVisiblity },
    crm: CrmSystem.INFONOVA,
  },
  modellAStore: {
    url: '/modella/store',
    i18n: 'pages.modellAStore.name',
    requireOneOfServices: [Service.Tv],
    crm: CrmSystem.INFONOVA,
    partnerVisibility: { ...commonNorwegianPartnerOnlyVisiblity },
    customerType: CustomerType.PRIVAT,
  },
  myDevices: {
    url: `${CONTEXT_PATH}/internett/nettverk/enheter/:deviceId`,
    i18n: 'pages.network.devices.name',
    requireOneOfServices: [Service.Internet],
    partnerVisibility: { ...commonNorwegianPartnerOnlyVisiblity },
    crm: CrmSystem.INFONOVA,
  },
  movieRentalHistory: {
    url: `${CONTEXT_PATH}/tv/filmleiehistorikk`,
    i18n: 'pages.movieRentalHistory.name',
    requireOneOfServices: [Service.Tv],
    customerType: CustomerType.PRIVAT,
    partnerVisibility: {
      partners: [Partner.NO_EidsivaBredbaand, Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Blacklist,
    },
    crm: CrmSystem.INFONOVA,
  },
  myProfileDk: {
    url: `${window.origin}/mine-sider/min-profil`,
    i18n: 'pages.myProfile.name',
    partnerVisibility: {
      partners: [Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Whitelist,
    },
    description: 'pages.myProfile.description',
    crm: CrmSystem.INFONOVA,
  },
  firewallCreateRule: {
    url: `${CONTEXT_PATH}/internett/innstillinger/portviderekobling/ny-regel`,
    i18n: 'pages.firewall.createNewFirewallRule.heading',
    requireOneOfServices: [Service.Internet],
    customerType: CustomerType.PRIVAT,
    crm: CrmSystem.INFONOVA,
  },
  firewallUpdateRule: {
    url: `${CONTEXT_PATH}/internett/innstillinger/portviderekobling/regel/:ruleId`,
    i18n: 'pages.firewall.buttons.updateFirewallRule',
    requireOneOfServices: [Service.Internet],
    customerType: CustomerType.PRIVAT,
    crm: CrmSystem.INFONOVA,
  },
  meshNetworkStore: {
    url: `${window.origin}/bestill/wifioveralt/order`,
    i18n: 'pages.meshNetworkStore.name',
    partnerVisibility: { ...commonNorwegianPartnerOnlyVisiblity },
    customerType: CustomerType.PRIVAT,
    crm: CrmSystem.INFONOVA,
  },
  newsletterSubscriptions: {
    url: `${CONTEXT_PATH}/kommunikasjon`,
    i18n: 'pages.newsletterSubscriptions.name',
    crm: CrmSystem.INFONOVA,
    partnerVisibility: { ...commonNorwegianPartnerOnlyVisiblity },
    description: 'pages.newsletterSubscriptions.description',
  },
  relocation: {
    url: `${CONTEXT_PATH}/konto/melde-flytting/`,
    i18n: 'pages.relocationForm.name',
    crm: CrmSystem.INFONOVA,
    partnerVisibility: {
      ...commonNorwegianPartnerOnlyVisiblity,
      partners: [...commonNorwegianPartnerOnlyVisiblity.partners, Partner.NO_VikenFiber, Partner.NO_Lyse],
    },
    description: 'pages.relocationForm.description',
    customerType: CustomerType.PRIVAT,
  },
  relocationFormVikenFiber: {
    url: 'https://vikenfiber.altibox.no/flytteskjema/',
    i18n: 'pages.relocationFormVikenFiber.name',
    partnerVisibility: {
      partners: [Partner.NO_VikenFiber],
      filterType: FilterType.Whitelist,
    },
    description: 'pages.relocationFormVikenFiber.description',
    customerType: CustomerType.PRIVAT,
  },
  relocationFormLyse: {
    url: `${config.partnerConfig.lyse.relocationUrl}`,
    i18n: 'pages.relocationFormLyse.name',
    partnerVisibility: {
      partners: [Partner.NO_Lyse],
      filterType: FilterType.Whitelist,
    },
    description: 'pages.relocationFormLyse.description',
    customerType: CustomerType.PRIVAT,
  },
  tvStore: {
    url: `${window.origin}/bestill/kanaler/order`,
    i18n: 'pages.tvStore.name',
    requireOneOfServices: [Service.Tv],
    partnerVisibility: { ...commonNorwegianPartnerOnlyVisiblity },
    crm: CrmSystem.INFONOVA,
    customerType: CustomerType.PRIVAT,
  },
  xboxStore: {
    url: 'https://xbox.altibox.no/store',
    i18n: 'pages.xboxStore.name',
    crm: CrmSystem.INFONOVA,
    partnerVisibility: { ...commonNorwegianPartnerOnlyVisiblity },
    customerType: CustomerType.PRIVAT,
  },
  voip: {
    url: `${window.origin}/mine-sider/ip-telefoni/innstillinger/`,
    i18n: 'pages.voip.name',
    requireOneOfServices: [Service.Voip],
    partnerVisibility: { ...commonNorwegianPartnerOnlyVisiblity },
    crm: CrmSystem.INFONOVA,
    customerType: CustomerType.PRIVAT,
  },
  account: {
    i18n: 'pages.account.name',
    url: `${CONTEXT_PATH}/konto`,
  },
  authCallback: {
    i18n: 'pages.authCallback.name',
    url: `${CONTEXT_PATH}/auth/callback`,
  },
  changePin: {
    url: `${CONTEXT_PATH}/tv/endre-pin-koder`,
    i18n: 'pages.changePin.name',
    description: 'pages.changePin.description',
    requireOneOfServices: [Service.Tv],
    customerType: CustomerType.PRIVAT,
    crm: CrmSystem.INFONOVA,
  },
  hgwUserManual: {
    url: '/privat/kundeservice/hjelp-til-internett/hjemmesentral/',
    i18n: 'pages.hgwUserManual.name',
    partnerVisibility: {
      partners: [Partner.NO_EidsivaBredbaand, Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Blacklist,
    },
    customerType: CustomerType.PRIVAT,
    crm: CrmSystem.INFONOVA,
  },
  email: {
    url: `${CONTEXT_PATH}/konto/e-postkonto`,
    i18n: 'pages.email.name',
    partnerVisibility: {
      filterType: FilterType.Blacklist,
      partners: [Partner.NO_EidsivaBredbaand, Partner.DK_AltiboxOpenNet],
    },
    customerType: CustomerType.PRIVAT,
  },
  emailDkEnterprise: {
    url: `${CONTEXT_PATH}/konto/e-postkonto`,
    i18n: 'pages.email.name',
    partnerVisibility: {
      filterType: FilterType.Whitelist,
      partners: [Partner.DK_AltiboxOpenNet],
    },
  },
  emailCreateNew: {
    url: `${CONTEXT_PATH}/konto/e-postkonto/opprett-ny`,
    i18n: 'pages.email.name',
    ...emailCommonRequirements,
  },
  emailChangePassword: {
    url: `${CONTEXT_PATH}/konto/e-postkonto/:emailAccountId/endre-passord`,
    i18n: 'pages.emailChangePassword.name',
    ...emailCommonRequirements,
  },
  emailChangeSenderName: {
    url: `${CONTEXT_PATH}/konto/e-postkonto/:emailAccountId/endre-navn-paa-avsender`,
    i18n: 'pages.emailChangeSenderName.name',
    ...emailCommonRequirements,
  },
  emailDeleteAccount: {
    url: `${CONTEXT_PATH}/konto/e-postkonto/:emailAccountId/slett-konto`,
    i18n: 'pages.emailDeleteAccount.name',
    ...emailCommonRequirements,
  },
  emailActivateAccount: {
    url: `${CONTEXT_PATH}/konto/e-postkonto/:emailAccountId/aktiver-konto`,
    i18n: 'pages.emailActivateAccount.name',
    ...emailCommonRequirements,
  },
  home: {
    url: `${CONTEXT_PATH}`,
    i18n: 'pages.home.name',
  },
  internet: {
    url: `${CONTEXT_PATH}/internett`,
    i18n: 'pages.internet.name',
  },
  internetSettingsWifiBridgeMode: {
    url: `${CONTEXT_PATH}/internett/innstillinger/wifi/bromodus-bekreftelse`,
    i18n: 'pages.network.advanced.settings.sections.bridge.confirmation',
    requireOneOfServices: [Service.Internet],
    partnerVisibility: commonNorwegianPartnerOnlyVisiblity,
    customerType: CustomerType.PRIVAT,
    crm: CrmSystem.INFONOVA,
  },
  internetSettingsWifiBand: {
    url: `${CONTEXT_PATH}/internett/innstillinger/wifi/avansert/kombiner/:wifiSettingId`,
    i18n: 'pages.network.advanced.wifi.bandHeader',
    requireOneOfServices: [Service.Internet],
    partnerVisibility: commonNorwegianPartnerOnlyVisiblity,
    customerType: CustomerType.PRIVAT,
    crm: CrmSystem.INFONOVA,
  },
  internetSettingsWifiAdvanced: {
    url: `${CONTEXT_PATH}/internett/innstillinger/wifi/avansert/:wifiSettingId`,
    i18n: 'pages.network.advanced.wifi.advanced.name',
    requireOneOfServices: [Service.Internet],
    customerType: CustomerType.PRIVAT,
    crm: CrmSystem.INFONOVA,
  },
  internetSettingsOverview: {
    url: `${CONTEXT_PATH}/internett/innstillinger/oversikt`,
    i18n: 'pages.internetSettings.name',
    requireOneOfServices: [Service.Internet],
    partnerVisibility: commonNorwegianPartnerOnlyVisiblity,
    customerType: CustomerType.PRIVAT,
    crm: CrmSystem.INFONOVA,
  },
  internetSettingsOverviewDk: {
    url: `${CONTEXT_PATH}/internett/innstillinger/oversikt`,
    i18n: 'pages.internetSettings.name',
    requireOneOfServices: [Service.Internet],
    partnerVisibility: commonDanishPartnerOnlyVisibility,
    crm: CrmSystem.INFONOVA,
  },
  internetSettingsNetworkSettings: {
    url: `${CONTEXT_PATH}/internett/innstillinger/nettverk`,
    i18n: 'pages.internetSettings.name',
    requireOneOfServices: [Service.Internet],
    partnerVisibility: commonNorwegianPartnerOnlyVisiblity,
    customerType: CustomerType.PRIVAT,
    crm: CrmSystem.INFONOVA,
  },
  internetSettingsNetworkSettingsDk: {
    url: `${CONTEXT_PATH}/internett/innstillinger/nettverk`,
    i18n: 'pages.internetSettings.name',
    requireOneOfServices: [Service.Internet],
    partnerVisibility: commonDanishPartnerOnlyVisibility,
    crm: CrmSystem.INFONOVA,
  },
  internetSettingsPortForwarding: {
    url: `${CONTEXT_PATH}/internett/innstillinger/portviderekobling`,
    i18n: 'pages.internetSettings.name',
    requireOneOfServices: [Service.Internet],
    partnerVisibility: commonNorwegianPartnerOnlyVisiblity,
    customerType: CustomerType.PRIVAT,
    crm: CrmSystem.INFONOVA,
  },
  internetSettingsPortForwardingDk: {
    url: `${CONTEXT_PATH}/internett/innstillinger/portviderekobling`,
    i18n: 'pages.internetSettings.name',
    requireOneOfServices: [Service.Internet],
    partnerVisibility: commonDanishPartnerOnlyVisibility,
    crm: CrmSystem.INFONOVA,
  },
  invoiceLegacy: {
    url: `${window.origin}/mine-sider/faktura-legacy`,
    i18n: 'pages.invoice.name',
    partnerVisibility: {
      partners: [Partner.NO_EidsivaBredbaand],
      filterType: FilterType.Whitelist,
    },
    customerType: CustomerType.PRIVAT,
  },
  invoiceLyse: {
    url: `${config.partnerConfig.lyse.invoiceUrl}`,
    i18n: 'pages.invoice.name',
    partnerVisibility: {
      partners: [Partner.NO_Lyse],
      filterType: FilterType.Whitelist,
    },
  },
  logout: {
    url: `${CONTEXT_PATH}/logout`,
    i18n: 'pages.logout.name',
  },
  myProducts: {
    url: `${CONTEXT_PATH}/konto/produkter`,
    i18n: 'pages.myProducts.name',
    partnerVisibility: {
      partners: [Partner.NO_EidsivaBredbaand, Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Blacklist,
    },
    customerType: CustomerType.PRIVAT,
    crm: CrmSystem.INFONOVA,
  },
  myProductsDkEnterprise: {
    url: `${CONTEXT_PATH}/konto/produkter`,
    i18n: 'pages.myProducts.name',
    partnerVisibility: {
      partners: [Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Whitelist,
    },
    crm: CrmSystem.INFONOVA,
  },
  provAuthCallback: {
    url: `${CONTEXT_PATH}/auth/prov/callback`,
    i18n: 'pages.provAuthCallback.name',
  },
  streamingServices: {
    url: `${CONTEXT_PATH}/tv/strommetjenester`,
    i18n: 'pages.streamingServices.name',
    requireOneOfServices: [Service.Tv],
    partnerVisibility: {
      partners: [Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Blacklist,
    },
    customerType: CustomerType.PRIVAT,
    crm: CrmSystem.INFONOVA,
  },
  orderTrackerOpenNet: {
    url: `https://bestillinger.altibox.dk/summary`,
    i18n: 'pages.orderTrackerOpenNet.name',
    partnerVisibility: {
      partners: [Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Whitelist,
    },
  },
  streamingService: {
    url: `${CONTEXT_PATH}/tv/strommetjenester/:portalId`,
    i18n: 'pages.streamingService.name',
    requireOneOfServices: [Service.Tv],
    partnerVisibility: {
      partners: [Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Blacklist,
    },
    crm: CrmSystem.INFONOVA,
  },
  telephone: {
    url: `${CONTEXT_PATH}/telefoni`,
    i18n: 'pages.telephone.name',
  },
  tv: {
    url: `${CONTEXT_PATH}/tv`,
    i18n: 'pages.tv.name',
  },
  tvStoreOpenNet: {
    url: `${window.origin}/mine-sider/bestil-tv-pakker/`,
    i18n: 'pages.tvStoreOpenNet.name',
    requireOneOfServices: [Service.Tv],
    partnerVisibility: {
      partners: [Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Whitelist,
    },
  },
  users: {
    url: `${CONTEXT_PATH}/konto/brukere`,
    i18n: 'pages.users.name',
    partnerVisibility: {
      partners: [Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Blacklist,
    },
    crm: CrmSystem.INFONOVA,
  },
  userEdit: {
    url: `${CONTEXT_PATH}/konto/brukere/rediger`,
    i18n: 'pages.userEdit.name',
    partnerVisibility: {
      partners: [Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Blacklist,
    },
    crm: CrmSystem.INFONOVA,
  },
  userCreate: {
    url: `${CONTEXT_PATH}/konto/brukere/opprett`,
    i18n: 'pages.userCreate.name',
    partnerVisibility: {
      partners: [Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Blacklist,
    },
    crm: CrmSystem.INFONOVA,
  },
  userDelete: {
    url: `${CONTEXT_PATH}/konto/brukere/slett/:id`,
    i18n: 'pages.userDelete.name',
    partnerVisibility: {
      partners: [Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Blacklist,
    },
    crm: CrmSystem.INFONOVA,
    customerType: CustomerType.PRIVAT,
  },
  voipUsage: {
    url: `${CONTEXT_PATH}/internett/ip-telefoni/forbruk/`,
    i18n: 'pages.voipUsage.name',
    partnerVisibility: {
      partners: [Partner.DK_AltiboxOpenNet],
      filterType: FilterType.Blacklist,
    },
    requireOneOfServices: [Service.Voip, Service.TaVoip],
    crm: CrmSystem.INFONOVA,
  },

  // deliveryStatus is used as a catch-all for breadcrumbs and must be near bottom
  deliveryStatus: {
    url: `${CONTEXT_PATH}`,
    i18n: 'pages.deliveryStatus.name',
  },
  myHgw: {
    url: `${window.origin}/mine-sider/internett/min-hjemmesentral/`,
    i18n: 'pages.myHgw.name',
    requireOneOfServices: [Service.Internet],
    partnerVisibility: commonNorwegianPartnerOnlyVisiblity,
    customerType: CustomerType.BEDRIFT,
  },
  myUsageDK: {
    url: `${window.origin}/mine-sider/forbrugsoversigt`,
    i18n: 'pages.myUsageDK.name',
    partnerVisibility: commonDanishPartnerOnlyVisibility,
  },
  meshNetworkStoreDK: {
    url: 'https://altibox.dk/google-nest-wifi',
    i18n: 'pages.meshNetworkStore.name',
    partnerVisibility: commonDanishPartnerOnlyVisibility,
  },
  invoiceInformation: {
    url: `${window.origin}/mine-sider/hjaelp-til-din-faktura/`,
    i18n: 'pages.invoiceInformation.name',
    partnerVisibility: commonDanishPartnerOnlyVisibility,
  },
  invoiceOpenNet: {
    url: `${window.origin}/mine-sider/fakturaarkiv-inf/`,
    i18n: 'pages.invoiceOpenNet.name',
    partnerVisibility: commonDanishPartnerOnlyVisibility,
  },
  invoiceArchiveSiebel: {
    url: `${window.origin}/mine-sider/fakturaarkiv/`,
    i18n: 'pages.invoiceArchiveSiebel.name',
    partnerVisibility: commonDanishPartnerOnlyVisibility,
  },
  voipDK: {
    url: `${window.origin}/mine-sider/fastnet`,
    i18n: 'pages.voipDK.name',
    requireOneOfServices: [Service.Voip],
    partnerVisibility: commonDanishPartnerOnlyVisibility,
  },
  orderDK: {
    url: `${window.origin}/mine-sider/bestil`,
    i18n: 'pages.orderDK.name',
    partnerVisibility: commonDanishPartnerOnlyVisibility,
  },
};
