import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';

import { selectCustomerLocation } from '../actions/user-context-actions';
import { fetchPartner } from '../actions/partner-actions';
import { PartnerState, FetchStatus } from 'app/store/root-types';
import { logout } from '../actions/auth-actions';

const initialState: PartnerState = {
  fetchStatus: FetchStatus.NOT_STARTED,
};

const CmsPartnerSlice: Slice<PartnerState> = createSlice({
  name: 'partner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(selectCustomerLocation, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(fetchPartner.pending, (state) => {
      state.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(fetchPartner.fulfilled, (state, action: PayloadAction<MinesiderBackend.CmsPartner>) => {
      state.fetchStatus = FetchStatus.FULFILLED;
      state.data = action.payload;
    });
    builder.addCase(fetchPartner.rejected, (state) => {
      state.fetchStatus = FetchStatus.REJECTED;
    });
  },
});

const { reducer } = CmsPartnerSlice;
export { reducer };
