import { createAsyncThunk } from '@reduxjs/toolkit';

import { minesiderBackendService } from 'app/api/service/minesider-backend-service';
import { RootState } from '../configure-store';

export const getSubscriptions = createAsyncThunk<
  MinesiderBackend.CrmUserSubscriptions,
  void,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('subscriptions/getSubscriptions', async (_, thunkApi) => {
  const currentState = thunkApi.getState() as RootState;
  const crmId = currentState.userContext.selectedCustomerLocation!.crmId;
  return minesiderBackendService
    .getSubscriptions(crmId)
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse));
});

export const updateSubscriptions = createAsyncThunk<
  MinesiderBackend.CrmUserSubscriptions,
  MinesiderBackend.CrmUserSubscriptions,
  {
    rejectValue: MinesiderBackend.ErrorResponse;
  }
>('subscriptions/updateSubscriptions', async (updatedSubscriptions, thunkApi) => {
  const currentState = thunkApi.getState() as RootState;
  const crmId = currentState.userContext.selectedCustomerLocation!.crmId;
  return minesiderBackendService
    .updateSubscriptions(crmId, updatedSubscriptions)
    .catch((error) => thunkApi.rejectWithValue(error.data as MinesiderBackend.ErrorResponse));
});
