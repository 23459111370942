import * as Sentry from '@sentry/react';

import { config } from 'app/config/config';
import { sha256 } from 'app/utils/string-util';

export interface SentryErrorData {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  resetError(): void;
}

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below

  actionTransformer: (action) => ({
    type: action.type,
  }),
  stateTransformer: (state) => ({
    ...state,
    auth: {},
    userContext: {
      ...state.userContext,
      customerLocations: {
        ...state.userContext.customerLocations,
        firstName: null,
        lastName: null,
        customerNumber: null,
        customerCrmId: null,
        crmId: null,
        sessionTicket: null,
        address: {},
        servicePointId: null,
        siteId: null,
        siteName: null,
      },
      user: {},
      selectedCustomerLocation: {},
    },
    pin: {},
    relocation: {},
    email: {},
    network: {},
    contactDetails: {},
  }),
});

export async function setUser(user: MinesiderBackend.User): Promise<void> {
  if (config.sentry.enabled === 'true' && user.username) {
    sha256(user.username)
      .then((shasum) => Sentry.configureScope((scope) => scope.setUser({ username: shasum })))
      .catch((error) => Sentry.captureMessage('Unable to hash username: ' + error.message, 'info'));
  }
}
