import axios from 'axios';
import { v4 as uuid } from 'uuid';
import Cookies from 'js-cookie';

import { config } from 'app/config/config';
import { store, persistor } from 'app/store/configure-store';
import { ParamUtils } from 'app/utils/param-utils';
import { authCallbackSuccess, provAuthSuccess, startProvAuth } from 'app/store/actions/auth-actions';
import { raiseGlobalError } from 'app/store/actions/core-actions';
import { ACCESS_TOKEN_COOKIE, AUTH_STATE_PARAM, LOGIN_REDIRECT_URL } from 'app/utils/cookies';
import { NonRetryableSystemError } from 'app/components/error/error-types/non-retryable-system-error';
import { ErrorTypes, FriendlyErrorMessage } from 'app/store/root-types';
import { Pages } from 'app/service/navigation/pages';
import { resolveLocaleFromTld } from '../../utils/navigation-utils';

const CONTEXT_PATH: string = config.app.contextPath;

export class AuthService {
  public static authorize(): void {
    const state = uuid();
    const nonce = uuid();
    const locale = resolveLocaleFromTld();
    const { authorizationUrl, scope, clientId } = config.auth;

    Cookies.set(LOGIN_REDIRECT_URL, window.location.href);
    Cookies.set(AUTH_STATE_PARAM, state);

    window.location.assign(
      `${authorizationUrl}` +
        `?response_type=code` +
        `&client_id=${clientId}` +
        `&scope=openid ${scope}` +
        `&redirect_uri=${encodeURIComponent(window.origin)}${encodeURIComponent(Pages.authCallback.url)}` +
        `&state=${state}` +
        `&nonce=${nonce}` +
        `&theme=Altibox` +
        `&locale=${locale}`,
    );
  }

  private static raiseAuthenticationError(errorMessage: string) {
    store.dispatch(
      raiseGlobalError({
        message: FriendlyErrorMessage.BROWSER_COOKIES_DISABLED,
        type: ErrorTypes.AUTHENTICATION_SESSION_ERROR,
      }),
    );
  }

  public static handleAuthorizationCallback(): void {
    let requestState;
    try {
      requestState = Cookies.get(AUTH_STATE_PARAM);
    } catch (e) {
      this.raiseAuthenticationError(FriendlyErrorMessage.BROWSER_COOKIES_DISABLED);
      return;
    }

    const responseState: string | null = ParamUtils.getQueryParam('state');
    const code: string | null = ParamUtils.getQueryParam('code');

    if (responseState === null || requestState === undefined) {
      this.raiseAuthenticationError(
        `Authorization states are invalid. Response state is ${responseState} and request state is ${requestState} from referrer ${document.referrer} on href ${window.location.href}`,
      );
      return;
    }

    if (requestState === responseState) {
      Cookies.remove(AUTH_STATE_PARAM);
    } else {
      this.raiseAuthenticationError(
        `Authorization response state ${responseState} doesn't match request state ${requestState}`,
      );
      return;
    }

    if (!code) {
      this.raiseAuthenticationError('Authorization response is missing code parameter');
      return;
    }

    AuthService.fetchTokens(code)
      .then((tokensResponse) => {
        if (tokensResponse.accessToken && tokensResponse.idToken && tokensResponse.expiresIn) {
          Cookies.set(ACCESS_TOKEN_COOKIE, tokensResponse.accessToken);
          store.dispatch(
            authCallbackSuccess({
              accessToken: tokensResponse.accessToken,
              idToken: tokensResponse.idToken,
              sessionExpirationTimestamp: Date.now() + tokensResponse.expiresIn * 1000,
            }),
          );

          persistor.flush();

          const loginRedirectCookie = Cookies.get(LOGIN_REDIRECT_URL);

          if (loginRedirectCookie) {
            Cookies.remove(LOGIN_REDIRECT_URL);
            window.location.replace(loginRedirectCookie);
          } else {
            window.location.replace(CONTEXT_PATH);
          }
        } else {
          throw new NonRetryableSystemError('Invalid auth response');
        }
      })
      .catch((error) => {
        store.dispatch(raiseGlobalError(error));
      });
  }

  public static handleProvAuthorization(): void {
    const provcustomertoken: string | null = ParamUtils.getQueryParam('provcustomertoken');

    if (provcustomertoken) {
      store.dispatch(startProvAuth());

      AuthService.fetchSessionTicketFromProvToken(provcustomertoken)
        .then((response) => {
          if (response.value?.sessionTicket) {
            store.dispatch(
              provAuthSuccess({
                sessionTicket: response.value.sessionTicket,
                sessionExpirationTimestamp: Date.now() + 86400 * 1000, // 24H
              }),
            );
            window.location.replace(CONTEXT_PATH);
          } else {
            this.raiseAuthenticationError('Error during prov auth');
          }
        })
        .catch((error) => {
          store.dispatch(raiseGlobalError(error));
        });
    } else {
      AuthService.authorize();
    }
  }

  public static fetchTokens(code: string): Promise<MinesiderBackend.TokenResponse> {
    const { oauthProvider } = config.auth;

    return axios
      .create()
      .get<MinesiderBackend.TokenResponse>(`${config.app.contextPath}/api/custom-oauth/${oauthProvider}/token`, {
        params: {
          code,
          redirect_uri: `${window.origin}${Pages.authCallback.url}`,
          grant_type: 'authorization_code',
        },
      })
      .then((tokenResponse) => tokenResponse.data);
  }

  public static fetchSessionTicketFromProvToken(
    provcustomertoken: string,
  ): Promise<MinesiderBackend.AltiboxApiResponseSingleSignonResponse> {
    return axios
      .create()
      .get<MinesiderBackend.AltiboxApiResponseSingleSignonResponse>(
        `${config.app.contextPath}/api/auth/prov/sessionticket`,
        {
          params: {
            provcustomertoken,
          },
        },
      )
      .then((response) => response.data);
  }

  public static logout() {
    return axios
      .create()
      .post<void>(`${config.app.contextPath}/api/auth/logout`, null, {
        headers: {
          Token: AuthService.getAccessToken() || '',
        },
      })
      .then((response) => response.data);
  }

  public static getAccessToken(): string | undefined {
    return store.getState().auth.accessToken;
  }

  public static getSessionTicket(): string | undefined {
    return store.getState().auth.sessionTicket;
  }
}
