import { createSlice, Slice } from '@reduxjs/toolkit';
import 'core-js/actual/array/flat';

import { authCallbackSuccess, logout, startProvAuth } from 'app/store/actions/auth-actions';
import {
  fetchUserContextStarted,
  fetchUserContextFulfilled,
  fetchUserContextFailed,
  changeCustomerLocation,
  selectCustomerLocation,
} from 'app/store/actions/user-context-actions';

import {
  toCustomerType,
  NewCustomerLocationContext,
  ActiveCustomerLocationContext,
  InactiveCustomerLocationContext,
  Role,
  ConnectionType,
} from 'app/store/types/user-context-types';
import { FetchStatus, UserContextState } from 'app/store/root-types';
import { dismissVerificationPrompt, setGlobalError } from '../actions/core-actions';
import { Service } from 'app/service/navigation/pages/page-types';
import { getUserNeedToConfirm } from '../actions/user-context-thunks';
import { saveContactDetails } from '../actions/contact-details-thunks';

const initialState: UserContextState = {
  customerLocations: [],
  changingLocation: false,
  isFetchingData: false,
  contactDetailsConfirmed: {
    fetchStatus: FetchStatus.NOT_STARTED,
    needToVerify: false,
  },
};

const flatMapAccounts = (accounts: MinesiderBackend.Account[] | undefined) => {
  const context = accounts?.map((account) => mapLocations(account)).flat();
  return context || [];
};

const mapLocations = (account: MinesiderBackend.Account) =>
  account.locations && account.locations.length > 0
    ? account.locations?.map((location) => mapLocationContext(account, location))
    : mapLocationContext(account, null);

const getValue = (value?: string, nonValue?: string | null) => value || (nonValue ?? '');

const mapLocationContext = (account: MinesiderBackend.Account, location: MinesiderBackend.ServiceLocation | null) => {
  const context: NewCustomerLocationContext | ActiveCustomerLocationContext | InactiveCustomerLocationContext = {
    firstName: getValue(account.customer?.firstName),
    lastName: getValue(account.customer?.lastName),
    customerNumber: getValue(account.customer?.customerNumber),
    customerCrmId: getValue(account.customer?.customerCrmId),
    customerType: toCustomerType(account.customer?.customerType),
    crmId: getValue(account.customer?.crmId),
    status: account.customer?.status || 'NEW',
    crmSystem: getValue(account.customer?.crmSystem),
    partnerNumber: getValue(account.partner?.id),
    partnerName: getValue(account.partner?.name),
    partnerLegalName: getValue(account.partner?.legalName),
    partnerWebsite: getValue(account.partner?.website),
    sessionTicket: getValue(account.sessionTicket),
    role: getValue(account.role) as Role,
    equipmentList: location?.equipmentList,
    address: {
      city: getValue(location?.address?.city),
      country: getValue(location?.address?.country),
      postalcode: getValue(location?.address?.postalcode),
      streetaddress: getValue(location?.address?.streetaddress),
      streetaddress2: getValue(location?.address?.streetaddress2, null),
      dwellingUnitNumber: getValue(location?.address?.dwellingUnitNumber, null),
      id: getValue(location?.address?.id),
      name: getValue(location?.address?.name),
    },
    servicePointId: getValue(location?.servicePointId),
  };
  if (location?.siteId) {
    (context as ActiveCustomerLocationContext).siteId = location.siteId || 0;
    (context as ActiveCustomerLocationContext).siteName = location.siteName || '';
    (context as ActiveCustomerLocationContext).services = (location.services as Service[]) || [];
    (context as ActiveCustomerLocationContext).connectionType = location.connectionType as ConnectionType;
  } else if (location === null) {
    (context as InactiveCustomerLocationContext).formerCustomer = true;
  }
  return context;
};

const userContextSlice: Slice<UserContextState> = createSlice({
  name: 'userContext',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setGlobalError, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(startProvAuth, () => initialState);
    builder.addCase(authCallbackSuccess, () => initialState);
    builder.addCase(selectCustomerLocation, (state, action) => {
      state.selectedCustomerLocation = action.payload.customerLocation;
      state.contactDetailsConfirmed.needToVerify = false;
      state.changingLocation = false;
    });
    builder.addCase(changeCustomerLocation, (state) => {
      state.changingLocation = true;
    });
    builder.addCase(fetchUserContextStarted, (state) => {
      state.isFetchingData = true;
    });
    builder.addCase(fetchUserContextFulfilled, (state, action) => {
      state.customerLocations = flatMapAccounts(action.payload.accounts);

      state.user = action.payload.user;
      state.isFetchingData = false;
    });
    builder.addCase(fetchUserContextFailed, (state) => {
      state.isFetchingData = false;
    });
    builder.addCase(getUserNeedToConfirm.fulfilled, (state, action) => {
      state.contactDetailsConfirmed.fetchStatus = FetchStatus.FULFILLED;
      state.contactDetailsConfirmed.data = action.payload;
      state.contactDetailsConfirmed.needToVerify = action.payload.hasDetailsToBeConfirmed === true;
    });
    builder.addCase(saveContactDetails.fulfilled, (state) => {
      state.contactDetailsConfirmed.needToVerify = false;
    });
    builder.addCase(dismissVerificationPrompt, (state) => {
      state.contactDetailsConfirmed.needToVerify = false;
    });
    builder.addCase(getUserNeedToConfirm.pending, (state) => {
      state.contactDetailsConfirmed.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(getUserNeedToConfirm.rejected, (state) => {
      state.contactDetailsConfirmed.fetchStatus = FetchStatus.REJECTED;
    });
  },
});

export const { reducer } = userContextSlice;
