import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';

import { i18n } from 'app/i18n/i18n';
import { FetchStatus, UsersState } from 'app/store/root-types';
import { selectCustomerLocation } from 'app/store/actions/user-context-actions';
import { logout } from 'app/store/actions/auth-actions';
import { clearUsersToastMessageAndStatuses, clearUsersFetchStatus } from 'app/store/actions/users-actions';
import {
  createUserResponse,
  deleteUserResponse,
  getUsersResponse,
  updateUserResponse,
  resendVerificationEmail,
} from 'app/store/actions/users-thunks';

const initialState: UsersState = {
  fetchStatus: FetchStatus.NOT_STARTED,
  createUser: {
    fetchStatus: FetchStatus.NOT_STARTED,
  },
  updateUser: {
    fetchStatus: FetchStatus.NOT_STARTED,
  },
  deleteUser: {
    fetchStatus: FetchStatus.NOT_STARTED,
  },
  resendVerification: {
    fetchStatus: FetchStatus.NOT_STARTED,
  },
};

const usersSlice: Slice<UsersState> = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(selectCustomerLocation, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
    builder.addCase(clearUsersToastMessageAndStatuses, (state) => {
      state.toastMessage = undefined;
      state.createUser.fetchStatus = initialState.createUser.fetchStatus;
      state.updateUser.fetchStatus = initialState.updateUser.fetchStatus;
      state.deleteUser.fetchStatus = initialState.deleteUser.fetchStatus;
      state.resendVerification.fetchStatus = initialState.resendVerification.fetchStatus;
    });
    builder.addCase(clearUsersFetchStatus, (state) => {
      state.fetchStatus = initialState.fetchStatus;
    });
    builder.addCase(getUsersResponse.pending, (state) => {
      state.fetchStatus = FetchStatus.PENDING;
      state.users = undefined;
    });
    builder.addCase(getUsersResponse.fulfilled, (state, action: PayloadAction<MinesiderBackend.CrmUser[]>) => {
      state.fetchStatus = FetchStatus.FULFILLED;
      state.users = action.payload;
    });
    builder.addCase(getUsersResponse.rejected, (state) => {
      state.fetchStatus = FetchStatus.REJECTED;
    });

    builder.addCase(createUserResponse.pending, (state) => {
      state.createUser.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(createUserResponse.fulfilled, (state, action: PayloadAction<MinesiderBackend.CrmUser>) => {
      state.fetchStatus = FetchStatus.NOT_STARTED;
      state.createUser.fetchStatus = FetchStatus.FULFILLED;
      state.users = undefined;
      state.toastMessage = i18n.t('pages.userCreate.successMessage', { email: action.payload.email });
    });
    builder.addCase(createUserResponse.rejected, (state) => {
      state.createUser.fetchStatus = FetchStatus.REJECTED;
    });

    builder.addCase(updateUserResponse.pending, (state) => {
      state.updateUser.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(updateUserResponse.fulfilled, (state, action: PayloadAction<MinesiderBackend.CrmUser>) => {
      state.fetchStatus = FetchStatus.NOT_STARTED;
      state.updateUser.fetchStatus = FetchStatus.FULFILLED;
      state.users = undefined;
      state.toastMessage = i18n.t('pages.userEdit.successMessage', { email: action.payload.email });
    });
    builder.addCase(updateUserResponse.rejected, (state) => {
      state.updateUser.fetchStatus = FetchStatus.REJECTED;
    });

    builder.addCase(deleteUserResponse.pending, (state) => {
      state.deleteUser.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(deleteUserResponse.fulfilled, (state, action: PayloadAction<MinesiderBackend.CrmUser>) => {
      state.fetchStatus = FetchStatus.NOT_STARTED;
      state.deleteUser.fetchStatus = FetchStatus.FULFILLED;
      state.users = undefined;
      state.toastMessage = i18n.t('pages.userDelete.successMessage', { email: action.payload.email });
    });
    builder.addCase(deleteUserResponse.rejected, (state) => {
      state.deleteUser.fetchStatus = FetchStatus.REJECTED;
    });
    builder.addCase(resendVerificationEmail.rejected, (state, action) => {
      state.resendVerification.resendUserId = action.meta.arg;
      state.resendVerification.fetchStatus = FetchStatus.REJECTED;
    });
    builder.addCase(resendVerificationEmail.pending, (state, action) => {
      state.resendVerification.resendUserId = action.meta.arg;
      state.resendVerification.fetchStatus = FetchStatus.PENDING;
    });
    builder.addCase(resendVerificationEmail.fulfilled, (state, action) => {
      state.resendVerification.resendUserId = action.meta.arg;
      state.resendVerification.fetchStatus = FetchStatus.FULFILLED;
    });
  },
});

const { reducer } = usersSlice;

export { reducer };
